import React, {useEffect} from "react";
import {Loader} from "@livesession/design-system";
import {ConsentChallenge, useConsent} from "livesession-oauth-sdk";
import {ConsentScreen} from "livesession-auth-elements";
import {useAuth} from "livesession-auth-sdk";
import {getScopeGroups} from "livesession-consent";

// TODO: remove in the future - for some reasons importing from design-system doesn't work
// TODO: and it must be imported here
import "antd/lib/style/index.css"
import "antd/lib/collapse/style/index.css"

export const LIVESESSION_CONSENT = {
    name: "LiveSession",
    logo: `${process.env.PUBLIC_URL}/img/ls_logo_emblemat.jpeg`
}

export function ConsentScreenPage() {
    const auth = useAuth()
    const {isLoading, consent, error: consentError} = useConsent()

    // @ts-ignore // TODO:
    const scopeGroups = getScopeGroups(consent?.scopes)

    useEffect(() => {
        document.title = `${consent?.appName || ""} wants access to LiveSession`
    }, [consent?.appName])

    function logout() {
        auth.logout("/login" + window.location.search)
    }

    return (
        // TODO: design better ConsentScreen
        <ConsentChallenge token={auth.data?.token}>
            {
                ({authorize, deny, isLoading: consentLoading, splashLoading}) => {
                    if (!consent || consentError || splashLoading || auth.isLoading) {
                        return <Loader.Splash/>
                    }

                    return (
                        <>
                            <ConsentScreen
                                platform={LIVESESSION_CONSENT}

                                app={{
                                    name: consent?.appName || "",
                                    logo: consent?.logoUrl || "", // TODO:
                                    // @ts-ignore // TODO:
                                    scopes: scopeGroups,
                                }}

                                isLoading={consentLoading || isLoading}

                                onDeny={deny}
                                onGrant={authorize}

                                logout={logout}

                                user={auth.data?.login}
                            />
                        </>
                    )
                }
            }
        </ConsentChallenge>
    )
}

import React, {useEffect} from "react";
import {
    RouterProvider,
    createBrowserRouter,
} from "react-router-dom";

import {Pages} from "../pages";
import {Redirect} from "./Redirect";

function withDocumentTitle(Component: any, title: string) {
    return (props: any) => {
        useEffect(() => {
            document.title = title
        }, [])

        return <Component {...props}/>
    }
}

const LoginPage = withDocumentTitle(Pages.Login, "Log in to LiveSession")
const SignUpPage = withDocumentTitle(Pages.SignUp, "Sign up to LiveSession")

const router = createBrowserRouter([
    {
        path: "/",
        element: <Redirect.WithAuth/>,
        children: [
            {
                path: "",
                element: <Redirect.LoggedIn to="/consent"/>,
                children: [
                    {
                        path: "",
                        element: <LoginPage/>,
                    }
                ]
            },
            {
                path: "/signup",
                element: <Redirect.LoggedIn to="/consent"/>,
                children: [
                    {
                        path: "",
                        element: <SignUpPage/>,
                    }
                ]
            },
            {
                path: "/login",
                element: <Redirect.LoggedIn to="/consent"/>,
                children: [
                    {
                        path: "",
                        element: <LoginPage/>,
                    }
                ]
            },
            {
                path: "/consent",
                element: <Redirect.Error to="/login"/>,
                children: [
                    {
                        path: "",
                        element: <Pages.Consent/>,
                    }
                ]
            },
        ]
    },
])

export function Routes() {
    return (
        <RouterProvider router={router}/>
    )
}
// src/AuthClient.ts
var AuthClient = class {
  constructor(apiURL, appApiURL) {
    this.apiURL = process.env.REACT_APP_AUTH_API_URL || "http://localhost:14000/accounts";
    this.appApiURL = process.env.REACT_APP_API_URL || "http://localhost:3001";
    if (apiURL) {
      this.apiURL = apiURL;
    }
    if (appApiURL) {
      this.appApiURL = appApiURL;
    }
  }
  login(login, password) {
    return fetch(`${this.apiURL}/v1/auth/authenticate`, {
      method: "POST",
      body: JSON.stringify({
        login,
        password,
        platform_id: "webapp"
      }),
      credentials: "include"
    });
  }
  signup(login, password, token, currency, force, trackingValues, provider, shopifyShop, shopifyToken) {
    return fetch(`${this.appApiURL}/accounts`, {
      method: "POST",
      body: JSON.stringify({
        accounts: true,
        provider,
        shopify_shop: shopifyShop,
        shopify_token: shopifyToken,
        login,
        password,
        force,
        token,
        currency,
        ...trackingValues
      }),
      credentials: "include"
    });
  }
  ping() {
    return fetch(`${this.apiURL}/v1/auth/ping`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Cache-Control": "no-cache"
      }
    });
  }
  logout() {
    return fetch(`${this.apiURL}/v1/auth/logout`, {
      method: "POST",
      credentials: "include"
    });
  }
};

// src/react/useAuth.tsx
import { useContext } from "react";

// src/react/AuthContext.tsx
import React from "react";
var AuthContext = React.createContext({
  sdk: new AuthClient(),
  data: {
    login: "",
    token: ""
  },
  setData: () => {
  },
  error: null,
  setError: (error) => {
  },
  isLoading: false,
  setIsLoading: () => {
  },
  isError: false,
  setIsError: () => {
  },
  isDone: false,
  setIsDone: () => {
  },
  setLogoutTo: (to) => {
  },
  logoutTo: ""
});

// src/react/useAuth.tsx
function useAuth() {
  const {
    sdk,
    data,
    setData,
    error,
    setError,
    isLoading,
    setIsLoading,
    isError,
    setIsError,
    isDone,
    setIsDone,
    logoutTo,
    setLogoutTo
  } = useContext(AuthContext);
  return {
    data,
    isLoading,
    isDone,
    isError,
    setError,
    error,
    logoutTo,
    isLoggedIn: !!data.token,
    resetError() {
      setError(null);
    },
    resetState() {
      setIsLoading(false);
      setIsDone(false);
      setIsError(false);
      setLogoutTo("");
    },
    login(login, password) {
      setIsLoading(true);
      sdk.login(login, password).then((resp) => resp.json()).then((data2) => {
        if (data2 == null ? void 0 : data2.error) {
          setIsError(true);
          setError(data2.error);
        }
        setData({
          login: data2.login,
          token: data2.token
        });
      }).catch(() => {
        setIsError(true);
      }).finally(() => {
        setIsLoading(false);
        setIsDone(true);
      });
    },
    signup(login, password, token, currency, force, trackingValues, provider, shopifyShop, shopifyToken) {
      setIsLoading(true);
      sdk.signup(login, password, token, currency, force, trackingValues, provider, shopifyShop, shopifyToken).then((resp) => resp.json()).then((data2) => {
        if (data2 == null ? void 0 : data2.error) {
          setIsError(true);
          setError(data2.error);
        }
        setData({
          login,
          token: data2.token
        });
      }).catch(() => {
        setIsError(true);
      }).finally(() => {
        setIsLoading(false);
        setIsDone(true);
      });
    },
    ping() {
      setIsLoading(true);
      sdk.ping().then((resp) => resp.json()).then((data2) => {
        setData({
          login: data2.login,
          token: data2.token
        });
      }).catch(() => {
        setIsError(true);
      }).finally(() => {
        setIsLoading(false);
        setIsDone(true);
      });
    },
    logout(to) {
      setIsLoading(true);
      sdk.logout().then((resp) => resp.json()).then(() => {
        setLogoutTo(to);
        setData();
      }).catch(() => {
        setIsError(true);
      }).finally(() => {
        setIsLoading(false);
        setIsDone(true);
      });
    }
  };
}

// src/react/AuthProvider.tsx
import React2, { useState } from "react";
function AuthProvider(props) {
  const [data, setData] = useState({
    login: "",
    token: ""
  });
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const [logoutTo, setLogoutTo] = useState("");
  const authCtxValue = {
    sdk: props.client,
    data,
    setData(data2) {
      setData(data2 || {
        login: "",
        token: ""
      });
    },
    error,
    setError,
    isLoading,
    setIsLoading,
    isError,
    setIsError,
    isDone,
    setIsDone,
    setLogoutTo(to) {
      setLogoutTo(to || "");
    },
    logoutTo
  };
  return /* @__PURE__ */ React2.createElement(AuthContext.Provider, {
    value: authCtxValue
  }, props.children);
}
export {
  AuthClient,
  AuthProvider,
  useAuth
};

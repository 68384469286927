// src/ThirdPartyLogin.tsx
import styled from "styled-components";
var ThirdPartyLogInButton = styled.button`
  background: #ffffff;
  border: 1px solid #000000;
  border-radius: 4px;
  max-width: 100%;
  height: 42px;
  text-transform: initial;
  align-items: center;
  display: block;
  justify-content: center;
  line-height: normal;
  color: #000000;
  transition: all 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  cursor: pointer;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  padding: 8px 0;
  width: 100%;
  max-width: 230px;
  margin: auto;
  outline: 0;

  &.disabled {
    background-color: #bfbfbf;
    border-color: #bfbfbf;
    color: #ffffff;

    &:hover {
      border-color: #bfbfbf;
      color: #ffffff;
    }
  }

  &:hover {
    border-color: #000;
    color: #fff;
    background-color: #000;
  }
`;

// src/ConsentScreen.tsx
import * as React from "react";
import { Button, Collapse, Panel, Placeholder } from "@livesession/design-system";

// src/ConsentScreen.style.tsx
import styled2 from "styled-components";
var Wrapper = styled2.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  padding: 50px;
`;
var Header = Object.assign(styled2.div`
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex: 1 1 auto;
  display: flex;
  gap: 20px;
`, {
  FontWrapper: styled2.div`
      font-size: 20px;
      margin-top: 15px;
      margin-bottom: 15px;
    `,
  ImageWrapper: styled2.div`
      padding: 15px;
    `,
  Image: styled2.img`
      width: 60px;
      height: 60px;
      border-radius: ${(props) => props.border ? "4px" : void 0}
    `
});
var Scopes = Object.assign(
  styled2.div`
    `,
  {
    Wrapper: styled2.div`
          .t__body {
            font-size: 14px;
            line-height: 20px;
            color: #222;
          }

          .text-gray {
            --tw-text-opacity: 1;
            color: rgba(117, 117, 117, var(--tw-text-opacity));
          }
        `,
    SubText: styled2.p`
          font-size: 14px;
          line-height: 25px;
          margin-bottom: 16px;
        `,
    IconWrapper: styled2.div`
          svg {
            width: 50px;
            height: 50px;
          }
        `,
    Ul: styled2.ul`
          list-style: initial;
          padding: 0 35px;
        `,
    Li: styled2.li``,
    ThesePermissions: styled2.div`
          color: rgba(117, 117, 117, 1);
        `
  }
);
var ActionButtons = styled2.div`
  display: flex;
  margin-top: 20px;
  gap: 10px;
  width: calc(50% - 5px)
`;
var Hr = styled2.div`
  position: relative;
  display: block;
  margin: 16px 0 16px;
  font-size: 13px;
  color: #a7a7a7;
  background: #fff;
  text-align: center;

  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    border-top: 1px solid #dbe1e2;
  }

  span {
    position: relative;
    z-index: 1;
    //background: #fff;
    padding: 0 30px;
  }
`;
var ReviewInfo = styled2.div`
  text-align: center;
  font-size: 13px;
  line-height: 20px;
  color: #757575;
  font-weight: 400;

  margin-top: 20px;
`;

// src/ConsentScreen.tsx
function ConnectLogo() {
  return /* @__PURE__ */ React.createElement("svg", {
    width: 32,
    height: 58,
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, /* @__PURE__ */ React.createElement("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M20.678 8.756v5.016H10v4.46h10.68v5.016c0 .756.496.996 1.12.476l8.474-6.808c.29-.276.456-.596.456-.916 0-.356-.166-.676-.456-.916L21.798 8.24c-.206-.16-.416-.24-.58-.24-.042.04-.124.04-.208.04-.206.116-.332.356-.332.716zM2 15.392v1.672h.004c.016.78.682 1.4 1.496 1.396h2.776V14h-2.78C2.666 14 2 14.636 2 15.392zm9.322 33.852v-5.016H22v-4.46H11.32v-5.016c0-.756-.496-.996-1.12-.476l-8.474 6.808c-.29.276-.456.596-.456.916 0 .356.166.676.456.916l8.476 6.844c.206.16.416.24.58.24.042-.04.124-.04.208-.04.206-.116.332-.356.332-.716zM30 42.608v-1.672h-.004c-.016-.78-.682-1.4-1.496-1.396h-2.776V44h2.78c.83 0 1.496-.636 1.496-1.392z",
    fill: "#888"
  }));
}
function Header2({ appName, platformName, appLogo, platformLogo, text }) {
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Header, null, /* @__PURE__ */ React.createElement(Header.ImageWrapper, null, /* @__PURE__ */ React.createElement(Header.Image, {
    src: appLogo
  })), /* @__PURE__ */ React.createElement(ConnectLogo, null), /* @__PURE__ */ React.createElement(Header.ImageWrapper, null, /* @__PURE__ */ React.createElement(Header.Image, {
    border: true,
    src: platformLogo
  }))), /* @__PURE__ */ React.createElement(Header.FontWrapper, null, text || /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("b", null, appName), " wants access ", /* @__PURE__ */ React.createElement("b", null, platformName))));
}
function ScopePanels({ appName, scopeGroups }) {
  return (scopeGroups || []).map(
    ({
      icon: IconElement,
      title,
      shortDescription,
      fullDescription,
      scopes
    }) => {
      const header = /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Scopes.IconWrapper, null, /* @__PURE__ */ React.createElement(IconElement, {
        style: { width: 32, height: 32, display: "flex" }
      })), /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("h3", {
        className: "onboarding-title"
      }, title), /* @__PURE__ */ React.createElement("p", {
        className: "onboarding-subtitle"
      }, shortDescription)));
      return /* @__PURE__ */ React.createElement(Panel, {
        header,
        headerIcon: null,
        key: title
      }, /* @__PURE__ */ React.createElement(Scopes.SubText, null, /* @__PURE__ */ React.createElement(Scopes.ThesePermissions, null, "These permissions will be granted to ", appName, ":")), /* @__PURE__ */ React.createElement(Scopes.Wrapper, null, /* @__PURE__ */ React.createElement(Scopes.Ul, null, scopes.map((scope) => /* @__PURE__ */ React.createElement(Scopes.Li, {
        className: "t__body text-gray",
        key: scope
      }, scope)))));
    }
  );
}
function ConsentScreen(props) {
  const { app, platform, placeholder, isLoading, onGrant, onDeny } = props;
  if (placeholder) {
    return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Placeholder, null), /* @__PURE__ */ React.createElement(Placeholder, null), /* @__PURE__ */ React.createElement(Placeholder, null));
  }
  let scopes;
  if (app.scopes) {
    scopes = ScopePanels({ appName: app.name, scopeGroups: app.scopes });
  }
  return /* @__PURE__ */ React.createElement(Wrapper, null, /* @__PURE__ */ React.createElement(Header2, {
    appName: app == null ? void 0 : app.name,
    platformName: platform == null ? void 0 : platform.name,
    appLogo: app == null ? void 0 : app.logo,
    platformLogo: platform == null ? void 0 : platform.logo
  }), /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(Collapse, null, scopes), /* @__PURE__ */ React.createElement(ActionButtons, null, /* @__PURE__ */ React.createElement(Button, {
    kind: "secondary",
    onClick: onDeny,
    disabled: isLoading,
    fullWidth: true
  }, "Cancel"), /* @__PURE__ */ React.createElement(Button, {
    onClick: onGrant,
    disabled: isLoading,
    loading: isLoading,
    fullWidth: true
  }, "Authorize access"))), /* @__PURE__ */ React.createElement(ReviewInfo, null, "Review this integration's privacy policy and terms of service. ", /* @__PURE__ */ React.createElement("br", null), "Revoke access by viewing your authorized integrations in your account."), props.logout && /* @__PURE__ */ React.createElement(ReviewInfo, null, "Logged as ", props.user, " (", /* @__PURE__ */ React.createElement("a", {
    onClick: props.logout
  }, "Logout"), ")"));
}
ConsentScreen.Header = Header2;

// src/index.tsx
var ThirdParty = Object.assign({}, {
  Button: ThirdPartyLogInButton
});
export {
  ConsentScreen,
  ThirdParty
};

import React, {useCallback} from 'react';
import {Button, FormControl} from "@livesession/design-system";

import {useAuth} from "livesession-auth-sdk";
import {GoogleLogin, LogInError, LogInForm} from "livesession-auth";
import {ConsentError} from "livesession-consent";
import {useConsent} from "livesession-oauth-sdk";

import {Container, Content, DontHaveAcc, OR, Wrapper} from "./style";

export function LoginPage() {
    return (
        <Container>
            <Content>
                <Header/>

                <Form/>

                <OR>
                    <span>or</span>
                </OR>

                <ThirdPartLogIn/>

                <Footer/>
            </Content>
        </Container>
    );
}

function Form() {
    const consent = useConsent()
    const auth = useAuth()

    const clickCallback = useCallback(() => {
        auth.resetError()
        consent.resetError()
    }, [])

    return (
        <LogInForm>
            <FormControl>
                <LogInForm.Email/>
            </FormControl>

            <FormControl>
                <LogInForm.Password/>
            </FormControl>

            {/*TODO: in the future*/}
            {/*<div style={{margin: "24px 0 32px 0"}}>*/}
            {/*    <input*/}
            {/*        name="forgot-password"*/}
            {/*        type="checkbox"*/}
            {/*        style={{fontSize: 13, color: "#000"}}*/}
            {/*    />*/}

            {/*    <label htmlFor="forgot-password">Remember me</label>*/}
            {/*    /!*<Checkbox disabled={apiLoading} onChange={(e) => setRemember(e.target.checked)} style={{ fontSize: 13, color: "#000" }}>*!/*/}
            {/*    /!*    Remember me*!/*/}
            {/*    /!*</Checkbox>*!/*/}
            {/*    <ForgotPassword href="/password/forgot">Forgot password?</ForgotPassword>*/}
            {/*</div>*/}

            <Button
                size="large"
                fullWidth
                disabled={auth.isLoading}
                loading={auth.isLoading}
                onClick={clickCallback}
            >
                Log in
            </Button>
        </LogInForm>
    )
}

function Header() {
    return (
        <Wrapper>
            <img src={process.env.PUBLIC_URL + '/img/ls_logo.png'} alt="logo"/>
            <h3>Welcome back! Please log in</h3>
            <LogInError/>
            <ConsentError/>
        </Wrapper>
    )
}

// TODO: microsoft
function ThirdPartLogIn() {
    return (
        <GoogleLogin/>
    )
}

function Footer() {
    return (
        <DontHaveAcc>
            Don&apos;t have an account? <a href={`/signup` + window.location.search + `&utm_source=app_signin`}>Sign
            up free</a>
        </DontHaveAcc>
    )
}


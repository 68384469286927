import React, { useCallback, useEffect, useState } from "react";
import { Button, FormControl, Loader } from "@livesession/design-system";
import { useNavigate } from "react-router-dom";

import { useAuth } from "livesession-auth-sdk";
import { useOAuthClient } from "livesession-oauth-sdk";
import { ConsentScreen } from "livesession-auth-elements";
import {
  SignUpError,
  SignUpForm,
  useSignUpForm,
  useDomainExists,
} from "livesession-auth";

import {
  Container,
  Content,
  DomainExists,
  DontHaveAcc,
  OR,
  SignUpInfo,
  Wrapper,
} from "./style";

interface App {
  name: string;

  logoUrl: string;
}

export function SignUpPage() {
  const domainExists = useDomainExists();

  return (
    <SignUpForm
      force={domainExists}
      recaptchaKey={
        process.env.REACT_APP_RECAPTCHA_URL ||
        "6Lfqu-QUAAAAACTEwVfQrw3A8CWAzcWOzJWvK-H-"
      }
    >
      {domainExists ? <DomainExistsSignUp /> : <SignUp />}
    </SignUpForm>
  );
}

export function SignUp() {
  const client = useOAuthClient();

  const [appLoading, setAppLoading] = useState(true);
  const [app, setApp] = useState<App | null>(null);

  async function getApp() {
    try {
      const clientIdUrl =
        new URLSearchParams(window.location.search).get("client_id") || "";

      const resp = await client.getAppByClientId(clientIdUrl);
      const data = await resp.json();

      setApp({
        name: data.name,
        logoUrl: data.logo_url,
      });
    } catch (e) {
      console.error(e);
    } finally {
      setAppLoading(false);
    }
  }

  useEffect(() => {
    getApp();
  }, []);

  if (appLoading) {
    return <Loader.Splash />;
  }

  return (
    <Container>
      <Content>
        <Header appName={app?.name || ""} appLogo={app?.logoUrl || ""} />

        <Form />

        {/*<OR>*/}
        {/*    <span>or</span>*/}
        {/*</OR>*/}

        {/*<ThirdPartySignUp/>*/}

        <Footer />
      </Content>
    </Container>
  );
}

function Form() {
  const auth = useAuth();

  const clickCallback = useCallback(() => {
    auth.resetError();
  }, []);

  return (
    <>
      <FormControl>
        <SignUpForm.Email />
      </FormControl>

      <FormControl>
        <SignUpForm.Password />
      </FormControl>

      <Button
        size="large"
        fullWidth
        disabled={auth.isLoading}
        loading={auth.isLoading}
        onClick={clickCallback}
      >
        Sign up free
      </Button>
    </>
  );
}

function Header({ appName, appLogo }: { appName: string; appLogo: string }) {
  return (
    <Wrapper>
      <ConsentScreen.Header
        appName={appName}
        appLogo={appLogo}
        platformName="LiveSession"
        platformLogo={`${process.env.PUBLIC_URL}/img/ls_logo_emblemat.jpeg`}
        text={
          <>
            Sign up to authorize your LiveSession account to <b>Shopify</b>
          </>
        }
      />

      {/*<img src={process.env.PUBLIC_URL + '/img/ls_logo.png'} alt="logo"/>*/}

      <SignUpError />
    </Wrapper>
  );
}

// TODO: in the future
// function ThirdPartySignUp() {
//     return (
//         <GoogleLogin/>
//     )
// }

function Footer() {
  return (
    <>
      <DontHaveAcc>
        Already have an account?{" "}
        <a href={`/login` + window.location.search}>Log in </a>
      </DontHaveAcc>

      <SignUpInfo>
        By clicking &quot;Sign Up free&quot; you agree to our{" "}
        <a href={`${process.env.REACT_APP_SITE_URL}/terms-of-service`}>
          terms of service
        </a>{" "}
        and{" "}
        <a
          href={`${process.env.REACT_APP_SITE_URL}/privacy-policy`}
          title="Privacy policy"
        >
          privacy policy
        </a>
        . This site is protected by reCAPTCHA and the Google{" "}
        <a target="_self" href="https://policies.google.com/privacy">
          privacy policy
        </a>{" "}
        and{" "}
        <a target="_self" href="https://policies.google.com/terms">
          terms of service
        </a>{" "}
        apply.
      </SignUpInfo>
    </>
  );
}

function getDomainFromEmail(email: string) {
  const ind = email.indexOf("@");

  return email.substring(ind + 1);
}

function DomainExistsSignUp() {
  const navigate = useNavigate();

  const auth = useAuth();
  const form = useSignUpForm();

  const domain = getDomainFromEmail(form.login);

  const clickGoBack = useCallback(() => {
    auth.resetError();
    navigate("/signup" + window.location.search);
  }, []);

  const clickCreateAccount = useCallback(() => {
    auth.resetError();
    form.submit(true);
  }, []);

  return (
    <>
      <Container>
        <Content>
          <DomainExists>
            <h1>Choose your path</h1>
            <p>
              An account using <strong>{domain}</strong> domain already exists.
              If you want to join this account ask your organization owner to
              send you an invitation.
            </p>

            <Button
              kind="secondary"
              size="large"
              fullWidth
              onClick={clickGoBack}
            >
              Go back
            </Button>
          </DomainExists>

          <OR>
            <span>or</span>
          </OR>

          <Button
            size="large"
            fullWidth
            disabled={auth.isLoading}
            loading={auth.isLoading}
            onClick={clickCreateAccount}
          >
            Create new account
          </Button>
        </Content>
      </Container>
    </>
  );
}

import React, {} from 'react';
import {AuthClient, AuthProvider} from "livesession-auth-sdk";

import {Routes} from "./routes";
import {OAuth2Client, OAuth2Provider} from "livesession-oauth-sdk";

const authClient = new AuthClient(process.env.REACT_APP_AUTH_API_URL); // configurable
const oauthClient = new OAuth2Client((process.env.REACT_APP_OAUTH_API_URL || "http://localhost:14000/accounts") + "/v1/oauth2")   // TODO: instead creating new oauth2 client maybe pass args to methods

function App() {
    return (
        <AuthProvider client={authClient}>
            <OAuth2Provider client={oauthClient}>
                <Routes/>
            </OAuth2Provider>
        </AuthProvider>
    );
}

export default App;

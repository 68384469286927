// src/types.ts
var SCOPE_USER_SESSIONS__READ = "users.sessions:read";
var SCOPE_USERS__READ = "users:read";
var SCOPE_WEBHOOKS__READ = "webhooks:read";
var SCOPE_WEBHOOKS__WRITE = "webhooks:write";
var SCOPE_ALERTS__READ = "alerts:read";
var SCOPE_ALERTS__WRITE = "alerts:write";
var SCOPE_WEBSITES_READ = "websites:read";
var SCOPE_WEBSITES_WRITE = "websites:write";
var SCOPES = {
  SCOPE_USER_SESSIONS__READ,
  SCOPE_USERS__READ,
  SCOPE_WEBHOOKS__READ,
  SCOPE_WEBHOOKS__WRITE,
  SCOPE_ALERTS__READ,
  SCOPE_ALERTS__WRITE,
  SCOPE_WEBSITES_READ,
  SCOPE_WEBSITES_WRITE
};

// src/OAuth2Client.ts
var OAuth2Client = class {
  constructor(apiURL) {
    this.apiURL = process.env.REACT_APP_AUTH_API_URL || "http://localhost:14000/accounts/v1/oauth2";
    if (apiURL) {
      this.apiURL = apiURL;
    }
  }
  getConsentChallenge(clientId) {
    return fetch(`${this.apiURL}/consent_challenge?client_id=` + clientId, {
      method: "POST",
      credentials: "include"
    });
  }
  approveConsentChallenge(clientId, id) {
    return fetch(`${this.apiURL}/consent_challenge/approve?client_id=` + clientId + "&consent_id=" + id, {
      method: "POST",
      credentials: "include"
    });
  }
  getAppByClientId(clientId) {
    return fetch(`${this.apiURL}/apps?client_id=` + clientId, {
      method: "GET"
    });
  }
  authorizeURL() {
    return `${this.apiURL}/authorize`;
  }
};

// src/react/useConsent.tsx
import { useContext as useContext2 } from "react";

// src/react/OAuth2Context.tsx
import React, { useContext } from "react";
var OAuth2Context = React.createContext({
  client: new OAuth2Client(""),
  consent: null,
  setConsent: () => {
  },
  error: null,
  setError: () => {
  },
  isLoading: false,
  setIsLoading: () => {
  },
  isError: false,
  setIsError: () => {
  },
  isDone: false,
  setIsDone: () => {
  }
});
function useOAuthClient() {
  return useContext(OAuth2Context).client;
}

// src/react/useConsent.tsx
function clientIdFromURL() {
  const clientIdUrl = new URLSearchParams(window.location.search).get("client_id");
  return clientIdUrl || "";
}
function useConsent() {
  const {
    client,
    consent,
    setConsent,
    error,
    setError,
    isLoading,
    setIsLoading,
    isError,
    setIsError,
    isDone,
    setIsDone
  } = useContext2(OAuth2Context);
  return {
    consent,
    isLoading,
    isError,
    isDone,
    error,
    resetError() {
      setError(null);
    },
    resetLoaders() {
      setIsLoading(false);
      setIsError(false);
      setIsDone(false);
    },
    getChallenge() {
      setIsLoading(true);
      const clientId = client.getConsentChallenge(clientIdFromURL()).then((response) => response.json()).then((data) => {
        if (data == null ? void 0 : data.error) {
          setError(data.error);
        }
        setConsent({
          ...consent,
          id: data.consent_id,
          approved: false,
          appName: data.app_name,
          logoUrl: data.logo_url,
          scopes: data.scopes
        });
      }).catch((error2) => {
        setIsError(true);
      }).finally(() => {
        setIsLoading(false);
      });
    },
    approve(onSuccess) {
      setIsLoading(true);
      client.approveConsentChallenge(clientIdFromURL(), (consent == null ? void 0 : consent.id) || "").then((response) => response.json()).then((data) => {
        setConsent({
          ...consent,
          approved: data.approved
        });
        onSuccess();
      }).catch((error2) => {
        setIsError(true);
      }).finally(() => {
        setIsLoading(false);
      });
    }
  };
}

// src/react/OAuth2Provider.tsx
import React2, { useState } from "react";
function OAuth2Provider(props) {
  const [consent, setConsent] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const authCtxValue = {
    client: props.client,
    consent,
    setConsent,
    error,
    setError,
    isLoading,
    setIsLoading,
    isError,
    setIsError,
    isDone,
    setIsDone
  };
  return /* @__PURE__ */ React2.createElement(OAuth2Context.Provider, {
    value: authCtxValue
  }, props.children);
}

// src/react/ConsentChallenge.tsx
import React3, { useContext as useContext3, useEffect, useRef, useState as useState2 } from "react";
function ConsentChallenge(props) {
  const oauth = useContext3(OAuth2Context);
  const {
    getChallenge,
    approve,
    consent,
    isLoading: consentLoading
  } = useConsent();
  const [formSubmitted, setFormSubmitted] = useState2(false);
  const [isLoading, setIsLoading] = useState2(consentLoading);
  const [splashLoading, setSplashLoading] = useState2(false);
  useEffect(() => {
    getChallenge();
  }, []);
  useEffect(() => {
    if (formSubmitted) {
      return;
    }
    setIsLoading(consentLoading);
  }, [consentLoading]);
  const elementRef = useRef();
  const childAPI = {
    deny() {
      setIsLoading(true);
      elementRef.current.click();
    },
    authorize() {
      setFormSubmitted(true);
      setIsLoading(true);
      approve(() => {
        elementRef.current.click();
      });
    },
    isLoading,
    splashLoading
  };
  function autoApprove() {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const autoapprove = params.get("auto_approve");
    if (autoapprove) {
      setSplashLoading(true);
      childAPI.authorize();
    }
  }
  useEffect(() => {
    if (!(consent == null ? void 0 : consent.id) || splashLoading) {
      return;
    }
    autoApprove();
  }, [consent, window.location.search, splashLoading]);
  return /* @__PURE__ */ React3.createElement(React3.Fragment, null, /* @__PURE__ */ React3.createElement("form", {
    action: oauth.client.authorizeURL() + window.location.search,
    method: "post"
  }, /* @__PURE__ */ React3.createElement("input", {
    type: "hidden",
    name: "token",
    value: props.token
  }), /* @__PURE__ */ React3.createElement("input", {
    type: "hidden",
    name: "consent_challenge",
    value: consent == null ? void 0 : consent.id
  }), /* @__PURE__ */ React3.createElement("button", {
    ref: elementRef,
    type: "submit",
    hidden: true
  }, "Submit")), props.children(childAPI));
}
export {
  ConsentChallenge,
  OAuth2Client,
  OAuth2Provider,
  SCOPES,
  useConsent,
  useOAuthClient
};

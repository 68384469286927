import React, { useEffect, useRef } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useAuth } from "livesession-auth-sdk";
import { useConsent } from "livesession-oauth-sdk";

const usePrevious = (value: string) => {
  const ref = useRef("");
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};

function WithAuth() {
  const navigate = useNavigate();

  const { ping, data, logoutTo, logout, resetState } = useAuth();
  const consent = useConsent();

  const prevLogin = usePrevious(data?.login);

  useEffect(() => {
    ping();
  }, []);

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const doLogout = params.get("logout"); // TODO: VERY, VERY BAD IDEA - IT SHOULD BE DONE BETTER

  if (doLogout) {
    params.delete("logout");

    const to = "/signup?" + params.toString();
    logout(to);
    navigate(to)

    return <Outlet />;
  }

  if (!consent?.error && prevLogin && !data?.login) {
    const to = logoutTo || "/";

    resetState();
    consent.resetError();
    navigate(to);
  }

  return <Outlet />;
}

function LoggedInRedirect({ to: propsTo }: { to: string }) {
  const navigate = useNavigate();

  const { isLoggedIn } = useAuth();

  useEffect(() => {
    if (isLoggedIn) {
      const redirectTo = new URLSearchParams(window.location.search).get(
        "redirect_to"
      );

      let to = redirectTo || propsTo;

      navigate(to + window.location.search);
    }
  }, [isLoggedIn]);

  return <Outlet />;
}

function ErrorRedirect({ to }: { to: string }) {
  const navigate = useNavigate();
  const { error } = useConsent();
  const { logout, isLoading, isDone, resetState } = useAuth();

  useEffect(() => {
    if (!isLoading && isDone && error) {
      resetState();
      navigate(to + window.location.search);
    }
  }, [isLoading, isDone]);

  useEffect(() => {
    if (error) {
      logout();
    }
  }, [error]);

  return <Outlet />;
}

export const Redirect = Object.assign(
  {},
  {
    WithAuth: WithAuth,
    LoggedIn: LoggedInRedirect,
    Error: ErrorRedirect,
  }
);

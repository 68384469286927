import styled from "styled-components"

export const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  overflow-y: auto;
  height: 100vh;
  /* display: flex; */
  position: relative;
  background-size: cover;

  & .small-screen {
    display: none;
    @media (max-width: 576px) {
      display: block;
    }
  }
`
export const Content = styled.div`
  max-width: 400px;
  width: 100%;
  height: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  position: relative;
  z-index: 10;
  /* justify-content: center; */

  button.login {
    width: 100%;
    padding: 5.5px 0;
    height: auto;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    line-height: normal;
  }
`

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  margin-top: 80px;
  margin-bottom: 16px;
  justify-content: center;
  flex-shrink: 0;
  flex-direction: column;
  text-align: center;
  
  img {
    max-width: 180px;
    margin: 0 auto 6px auto;
  }

  & h3 {
    font-size: 18px;
    text-align: center;
    margin: 12px 0 24px;
    font-weight: 400;
  }
`

export const OR = styled.div`
  position: relative;
  display: block;
  margin: 32px 0 32px;
  font-size: 13px;
  color: #a7a7a7;
  background: #fff;
  text-align: center;

  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    border-top: 1px solid #dbe1e2;
  }

  span {
    position: relative;
    z-index: 1;
    background: #fff;
    padding: 0 30px;
  }
`

export const DontHaveAcc = styled.p`
  text-align: center;
  font-size: 14px;
  margin-top: 24px;
  color: #000;
  
  & a {
    color: #0a4ed6;

    &:hover {
      color: #6ea3ff;
    }
  }
`

export const SignUpInfo = styled.p`
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    margin: 16px 0px 0px;
`

export const ForgotPassword = styled.a`
  float: right;
  font-size: 13px;
  color: #1749c2;
`

export const DomainExists = styled.div`
  margin-top: 100px;
  text-align: center;

  display: flex;
  gap: 25px;
  flex-direction: column;

  p {
    font-size: 18px;
  }
`